<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">
          {{ $t("title") }}
        </div>
        <!-- **************** OBJECIVE ******************** -->
        <div>
          <i18n path="objective.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("objective.title") }}</strong>
            </template>
          </i18n>
        </div>

        <!-- ***************** ACTION ************************** -->
        <div>
          <i18n path="action.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("action.title") }}</strong>
            </template>
          </i18n>
        </div>

        <!-- ************************ MATERIAL *************************** -->
        <div>
          <i18n path="material.text" tag="div" class="bsat__paragraph">
            <template #title>
              <strong>{{ $t("material.title") }}</strong>
            </template>
          </i18n>
        </div>
        <a :href="file" target="_blank" download>
          <v-btn
            depressed
            width="150"
            height="26"
            class="bsat__btn"
            dark
            color="dark"
            >{{ $t("download") }}</v-btn
          >
        </a>
      </div>

      <i18n
        class="mt-8"
        tag="div"
        path="downloadAll.text"
        style="font-family: Montserrat"
      >
        <template #link>
          <a class="dark-text mt-8" :href="zip" target="_blank" download>
            {{ $t("downloadAll.link") }}
          </a>
        </template>
      </i18n>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
	"en": {
		"action": {
			"text": "{title} Complete this questionnaire to get information of the current regional elements to consider in the bioeconomy field as well as the role of diverse stakeholders related to this topic.",
			"title": "Action:"
		},
		"downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 1 as a zip file please {link}"
		},
		"material": {
			"text": "{title} Characterisation questionnaire: Template to collect general information of a region",
			"title": "Material available:"
		},
		"objective": {
			"text": "{title}  to characterise  your region in the field of Bioeconomy. The form developed includes the minimum information required to have an helicopter view of all elements to consider from this step on as a baseline.",
			"title": "Objective:"
		},
		"title": "Step 1: Preliminary characterisation of the region"
	}
}
</i18n>

<script>
export default {
  name: "Phase1Step1",
  data: () => ({
    file: require("@/assets/files/1_1_1_Characterization_questionnaire.docx")
      .default,
    zip: require("@/assets/files/Phase1_Files.zip").default
  })
};
</script>
